import React from "react"
import PropTypes from "prop-types"
import * as S from "./styled"
import getThemeColor from "../../utils/getThemeColor"
const PostItem = ({
  image,
  slug,
  background,
  category,
  date,
  timeToRead,
  title,
  description,
}) => (
  <S.PostItemLink cover direction="right" bg={getThemeColor()} duration={0.6} to={slug}>
    <S.PostItemWrapper>
    <S.PostItemTag image={image}></S.PostItemTag>
    <S.PostItemInfo>
    <S.PostItemDate>
         {date} • {timeToRead} reading time
       </S.PostItemDate>
       <S.PostItemTitle>{title}</S.PostItemTitle>
       <S.PostItemDescription>{description}</S.PostItemDescription>
     </S.PostItemInfo>
   </S.PostItemWrapper>
 </S.PostItemLink>
)

PostItem.propTypes = {
 slug: PropTypes.string.isRequired,
 background: PropTypes.string,
 image: PropTypes.string,
 category: PropTypes.string.isRequired,
 date: PropTypes.string.isRequired,
 timeToRead: PropTypes.number.isRequired,
 title: PropTypes.string.isRequired,
 description: PropTypes.string.isRequired,
}


export default PostItem
